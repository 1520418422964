import React, {useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import StoreCard from "../storeCard";
import { API, graphqlOperation } from "aws-amplify";
import { useStateValue } from "../../state";
import Skeleton from '@material-ui/lab/Skeleton';


const listStores = `query ListStores{
  listStores {
    items{
      id
      name
      appLink
      profileImages
    }
  }
}`;

export default function StoreGrid(props) {
  const classes = useStyles();
  const [state, dispatch] = useStateValue();

  let listQuery = async () => {
    if ((state.storeList[0].id === "") && props.contents === "catalogs") {
      const queryResults = await API.graphql(graphqlOperation(listStores));
      dispatch({
        type: "updateStoreList",
        storeList: queryResults.data.listStores.items,
      });
    }
    
  };
  const cardList = state.storeList.map((card) => (
    <Grid key={'grid'+card.id} item  xs={4} sm={4} md={3}>
      <StoreCard 
        key={'card'+card.id} 
        name={card.name} 
        appLink={card.appLink}
        id={card.id} 
        image={(card.profileImages)?(card.profileImages[0]).replace("image/upload/", "image/upload/c_thumb,w_300/"):"https://res.cloudinary.com/ccolectivo/image/upload/c_thumb,w_300,g_face/v1589652793/stores/placeholder_es45gj.jpg"}
        description={(card.description !== null)?card.description:""}/>
    </Grid>
  ))

  const skeletonLoader =     
    <React.Fragment>
      <Grid key={'skeleton1'} item  xs={4} sm={4} md={3}>
        <Skeleton variant="rect" width={210} height={118} />
        <Skeleton variant="text" />
      </Grid>
      <Grid key={'skeleton2'} item  xs={4} sm={4} md={3}>
        <Skeleton variant="rect" width={210} height={118} />
        <Skeleton variant="text" />
      </Grid>
      <Grid key={'skeleton3'} item  xs={4} sm={4} md={3}>
        <Skeleton variant="rect" width={210} height={118} />
        <Skeleton variant="text" />
      </Grid>
      <Grid key={'skeleton4'} item  xs={4} sm={4} md={3}>
        <Skeleton variant="rect" width={210} height={118} />
        <Skeleton variant="text" />
      </Grid>
    </React.Fragment>

  useEffect(() => {
    listQuery();
  }, []);
  return (
    <React.Fragment>
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={2}>
          {(state.storeList[0].id === "")?skeletonLoader:cardList}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}));