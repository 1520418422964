import React from 'react';
import ProductGrid from '../../view/productGrid'

function Store(props){
  return (
    <React.Fragment>
    <ProductGrid contents="products"/>
    </React.Fragment>
  )
}
export default Store;