import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useStateValue } from "../../state";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Link as RouterLink, useHistory} from 'react-router-dom'
import { useFormik } from 'formik';
import {saveUserToDB, saveOrderToDB, createNewCheckout} from "../../services"
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({

}));
const formatter = new Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0
})
export default function Checkout() {
  const classes = useStyles()
  const [state, dispatch] = useStateValue()
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      notes: ''
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required('Ingrese su Nombre'),
      lastName: Yup.string()
        .required('Ingrese su Apellido'),
      email: Yup.string()
        .email('Correo Electronico Invalido')
        .required('Ingrese su Correo Electronico'),
      phone: Yup.string()
        .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Numero de telefono invalido')
        .required('Ingrese su Telefono'),
      address: Yup.string()
        .required('Ingrese la direccion de entrega'),
    }),
    onSubmit: async user => {
      console.log(user)
      //console.log(JSON.parse(user))
      console.log(JSON.stringify(user))
      const createUser = await saveUserToDB(user)
      const order = await saveOrderToDB(createUser.data.createUsers.id, state.checkout.id, state.checkout.cart, user.notes, user)
      history.push('/order/'+ order.data.createUserOrders.id)
      const emptyCart = await createNewCheckout()
      dispatch({
        type: "loadCheckout", 
        checkout: emptyCart.checkout
      })

    },
  });
  return (
<React.Fragment>
  <Container className={classes.cardGrid} maxWidth="md">
    <Grid container spacing={2} >
      <Grid item xs={12}><Typography align="center" variant="h5">Checkout</Typography></Grid>
      <Grid item xs={12}>
        <form noValidate autoComplete="off" method="post" onSubmit={formik.handleSubmit}>
          <TextField
            id="firstName"
            label="Nombres"
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            helperText={formik.touched.firstName && formik.errors.firstName ? (formik.errors.firstName) : null}
            error = {formik.touched.firstName && formik.errors.firstName ? true : false}
          />

          <TextField
            id="lastName"
            label="Apellidos"
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            helperText={formik.touched.lastName && formik.errors.lastName ? (formik.errors.lastName) : null}
            error = {formik.touched.lastName && formik.errors.lastName ? true : false}
          />
          <TextField
            id="email"
            label="Email"
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.email}
            helperText={formik.touched.email && formik.errors.email ? (formik.errors.email) : null}
            error = {formik.touched.email && formik.errors.email ? true : false}
          />
  
          <TextField
            id="phone"
            label="Telefono"
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.phone}
            helperText={formik.touched.phone && formik.errors.phone ? (formik.errors.phone) : null}
            error = {formik.touched.phone && formik.errors.phone ? true : false}
          />

          <TextField
            id="address"
            label="Direccion de Entrega"
            style={{ margin: 8 }}
            fullWidth
            multiline
            rows={4}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.address}
            helperText={formik.touched.address && formik.errors.address ? (formik.errors.address) : null}
            error = {formik.touched.address && formik.errors.address ? true : false}
          />

          <TextField
            id="notes"
            label="Notas Adicionales"
            style={{ margin: 8 }}
            fullWidth
            multiline
            rows={4}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.notes}
          />
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth 
            className={classes.checkoutButton} 
            component={RouterLink} 
            onClick={formik.handleSubmit}
            >
              Finalizar Pedido
          </Button>
        </form>
      </Grid>
    </Grid>
  </Container>
</React.Fragment>
  );
}
