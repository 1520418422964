import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useStateValue } from "../../state";
import {Link as RouterLink} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  }, 
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }, 
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  }, 
  img: {
    top: '50%',
    width: '100%',
    position: 'relative',
    transform: 'translateY(-50%)'
  }

}));
function Search(){
  const classes = useStyles();
  const [state] = useStateValue();
  return (
    <React.Fragment>
      <Container className={classes.cardGrid} maxWidth="md">
      <GridList cellHeight={180} className={classes.gridList}>
        <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
          <ListSubheader component="div">Resultados</ListSubheader>
        </GridListTile>
        {state.searchResults.map((tile) => (
        <GridListTile key={"search" + tile.id}>
          <RouterLink to={tile.appLink || ""}>
            <img src={tile.profileImages} alt={tile.name} className={classes.img}/>
            <GridListTileBar
              title={tile.name}
              subtitle={<span>{tile.name}</span>}
            />
          </RouterLink>
      </GridListTile>
        ))}
      </GridList>
      </Container>
    </React.Fragment>
  )
}
export default Search;