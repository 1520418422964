import React from 'react';
import StoreGrid from '../../view/storeGrid'

function Homepage () {

  return(
    <React.Fragment>
      <StoreGrid contents="catalogs"/>
    </React.Fragment>
    )
}

export default Homepage;
    