import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  }
 
}));

function Copyright() {
  
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {' © '}
      <Link color="inherit" href="/">
        Comercio Colectivo
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function CustomFooter(){
  const classes = useStyles();
  return(
      <footer className={classes.footer}>
      <Typography variant="h6" align="center" gutterBottom>
        
      </Typography>
      <Typography variant="subtitle1" align="center" color="textSecondary" component="p">Añade tu Tienda | Acerca de Comercio Colectivo | Contactanos</Typography>

      <Copyright />
    </footer>
            
  )
}
export default CustomFooter;