import React, {useState} from 'react'
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import { fade, makeStyles } from '@material-ui/core/styles';
import { useStateValue } from '../../../state';
import { API, graphqlOperation } from "aws-amplify";
import {Link as RouterLink, useHistory} from 'react-router-dom'

const searchStores = `query SearchStores($keywords: String) {
  listStores(filter: {keywords:{contains: $keywords}})
  {
    items{
      id
      name
      profileImages
      appLink
    }
  }
}`;

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: '35%',
    },
  },
  searchIcon: {
    color: 'white'
  },
  inputRoot: {
    color: 'inherit',
    width: '75%'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '90%', 
    },
  }
}));

export default function SearchBar(){
  const history = useHistory();
  const classes = useStyles();
  let [searchTerm, setSearchTerm] = useState('');
  const [,dispatch] = useStateValue();
  const handleChange = (elementValue)=>{
    setSearchTerm(elementValue.toLowerCase())
  }
  const handleKeyDown = (e)=>{
    if(e.key === "Enter"){
      searchQuery()
    }
  }
  let searchQuery = async () => {
      const queryResults = await API.graphql(graphqlOperation(searchStores, {keywords: searchTerm}));
      dispatch({
        type: "updateSearchResults",
        searchResults: queryResults.data.listStores.items,
      });
      history.push('/search')
  };
  return (
    <div className={classes.search}>
    <InputBase
      placeholder="Buscar Tienda…"
      classes={{
        root: classes.inputRoot,
        input: classes.inputInput,
      }}
      inputProps={{ 'aria-label': 'search' }}
      onChange= {e=> handleChange(e.target.value)}
      onKeyDown= {e=> handleKeyDown(e)} 
      fullWidth
    />
    <IconButton 
      type="submit" 
      className={classes.searchIcon} 
      aria-label="search" 
      onClick={searchQuery}
      to="/" 
      component={RouterLink} >
        <SearchIcon />
      </IconButton>
  </div>
  )
}