import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Link as RouterLink} from 'react-router-dom'
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  }

}));

function StoreCard(props){
  const classes = useStyles();
  return(
    <Link to= {{
      pathname: "/" + props.appLink,
      state: {
        id: props.id,
      }}} component={RouterLink} underline="none">
    <Card className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image={props.image}
        title={props.name}
      />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="subtitle1" component="h2">
          {props.name}
        </Typography>
        <Typography>
          
        </Typography>
      </CardContent>
    </Card>
    </Link>
  )
}
export default StoreCard
