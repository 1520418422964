import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Link as RouterLink} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  image:{
    width: '200px'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const formatter = new Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0
})
export default function AlertDialogSlide(props) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={props.displayModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.toggleFunction}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Producto Añadido"}
        <IconButton aria-label="close" className={classes.closeButton} onClick={props.toggleFunction}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <img src= {props.image} className={classes.image} alt={props.productName}/>
          <DialogContentText id="alert-dialog-slide-description">
            {props.productName}
          </DialogContentText>
          <Typography>{formatter.format(props.salePrice)}</Typography>
        </DialogContent>
        <DialogActions>
        <Button color="secondary" onClick={props.toggleFunction} >
            Seguir Viendo
          </Button>
          <Button  
            variant="contained" 
            color="primary" 
            to="cart" 
            component={RouterLink}>
            Finalizar Pedido
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}