import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useStateValue } from "../../state";
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {Link as RouterLink} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'},
  inline: {
    display: 'inline',
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  }, 
  checkoutButton: {

  }
}));
const formatter = new Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0
})
export default function Cart() {
  const classes = useStyles()
  const [state, dispatch] = useStateValue()

  let cartSubTotal = 0
  for(let x in state.checkout.cart){
    cartSubTotal += state.checkout.cart[x].storeSubTotal
  }

  const handleAddSubstractCartQuantity = function(store, product, quantity) {
    dispatch({
      type: "addVariantToCart",
      variantId: product,
      storeId: store,
      quantity: quantity
    });
  }
  let cartTableHtmlHeader = ""
  let cartTableHtmlFooter = ""
  if (Object.keys(state.checkout.cart).length !== 0){
    cartTableHtmlHeader = 
      <React.Fragment>
        <Grid item md={4} xs={6} ></Grid>
        <Grid item md={2} xs={3}><Typography align="center" variant="body1">Cantidad</Typography></Grid>
        <Grid item md={2} xs={3}><Typography align="center" variant="body1">Subtotal</Typography></Grid>
        <Hidden smDown>
          <Grid item md={4} xs={0}>
            <Button variant="contained" color="primary" fullWidth className={classes.checkoutButton} component={RouterLink} to="checkout">
              Finalizar Pedido
            </Button>
          </Grid>
        </Hidden>
      </React.Fragment>
    cartTableHtmlFooter = 
      <React.Fragment>
        <Grid item md={4} xs={6}></Grid>
        <Grid item md={2} xs={3}><Typography align="center" variant="body1">Subtotal: </Typography></Grid>
        <Grid item md={2} xs={3}><Typography align="center" variant="body1">{formatter.format(cartSubTotal)}</Typography></Grid>
        <Hidden smDown><Grid item md={4} xs={0}></Grid></Hidden>
        <Hidden mdUp>
          <Grid item sm={6}></Grid>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" fullWidth className={classes.checkoutButton} component={RouterLink} to="checkout">
              Finalizar Pedido
            </Button>
          </Grid>
        </Hidden>
      </React.Fragment>
  }
  return (
<React.Fragment>
  <Container className={classes.cardGrid} maxWidth="md">
    <Grid container spacing={2} alignItems="center">
      {cartTableHtmlHeader}
      {Object.keys(state.checkout.cart).map((store)=>(
        <React.Fragment>
          <Grid item xs={6} md={3}><Typography variant="h6">Tienda: {state.checkout.cart[store].name}</Typography></Grid>
          <Grid item xs={6} md={9}></Grid>
          {Object.keys(state.checkout.cart[store].products).map((product)=>
          
          (
            <React.Fragment>
            <Grid item md={4} xs={6}>
              <List className={classes.root}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt={state.checkout.cart[store].products[product].name} src={state.checkout.cart[store].products[product].image} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={state.checkout.cart[store].products[product].name}
                    secondary={formatter.format(state.checkout.cart[store].products[product].salePrice)}
                  />
                </ListItem>

              </List>
            </Grid>
            <Grid item md={2} xs={3}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <IconButton color="primary" component="span" onClick={(e)=>handleAddSubstractCartQuantity(store, product, -1)}>
                  <RemoveCircleRoundedIcon fontSize="small"></RemoveCircleRoundedIcon>
                </IconButton>
                <Typography className={classes.quantity}>{state.checkout.cart[store].products[product].quantity}</Typography>
                <IconButton color="primary" component="span" onClick={(e)=>handleAddSubstractCartQuantity(store, product, 1)}>
                  <AddCircleRoundedIcon fontSize="small"></AddCircleRoundedIcon>
                </IconButton>
              </Box>
            </Grid>
            <Grid item md={2} xs={3}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography align="center" className={classes.subtotal} variant="body1">{formatter.format(state.checkout.cart[store].products[product].productSubTotal)}</Typography>
              </Box>
            </Grid>
          <Hidden smDown>

            <Grid item md={4} xs={0}></Grid>
          </Hidden>
          
          
          </React.Fragment>
        ))}

        </React.Fragment>
      ))}
    <Grid item md={8} xs={12}><Divider variant="middle" /></Grid>
    <Hidden smDown><Grid item md={4}></Grid></Hidden>
      {cartTableHtmlFooter}
    </Grid>
  </Container>
</React.Fragment>
  );
}
