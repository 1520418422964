import {useEffect} from 'react';
import { useStateValue } from "../state";
import * as uuid from "uuid";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

const createUUID = ()=>uuid.v4()

const getUserOrders = `query GetOrders($id:ID!) {
  getOrders(id:$id){
    orderDate
    id
    cartId
    products
		user{
      items{
        user{
          firstName
          lastName
          phone
        }
      }
    }
    
  }
}`;

export async function loadOrder(orderNumber){
  console.log("order number: " + orderNumber)
  const order = await API.graphql(graphqlOperation(getUserOrders, {id: orderNumber}))
  //console.log(order)
  return (order)
}

export async function saveCartToDB(action, id, cart, totalLineItems, cartSubTotal){
  const cartInput = {id: id, cart: JSON.stringify(cart), totalLineItems: totalLineItems, cartSubTotal: 0}
  if(action === "create"){
    await API.graphql(graphqlOperation(mutations.createCheckoutCarts, {input: cartInput}))
  } else if (action === "update"){
    await API.graphql(graphqlOperation(mutations.updateCheckoutCarts, {input: cartInput}))
  }
}

export async function saveUserToDB(user){
  const userInput = {id: createUUID(), firstName: user.firstName, lastName: user.lastName, email: user.email}
  const response = await API.graphql(graphqlOperation(mutations.createUsers, {input: userInput}))
  return(response)
}

export async function saveOrderToDB(userId, cartId, products, notes, customer){
  console.log(customer)
  const orderInput = {id: createUUID(), userId: userId, cartId: cartId, products: JSON.stringify(products), notes: notes, customer: JSON.stringify(customer)}
  const order = await API.graphql(graphqlOperation(mutations.createOrders, {input: orderInput}))
  const userOrderInput = {id: orderInput.id, userId: userId, orderId: orderInput.id}
  const response = await API.graphql(graphqlOperation(mutations.createUserOrders, {input: userOrderInput}))
  return(response)
}

export function LoadCart(id){
  const [state, dispatch] = useStateValue();
  useEffect(() => {

    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== 'undefined'
      const existingCheckoutID = isBrowser
        ? localStorage.getItem('cColectivo_checkout_id')
        : null

      const setCheckoutInState = checkout => {
        //if (isBrowser) {
          //localStorage.setItem('cColectivo_checkout_id', createUUID())
          dispatch({
            type: "loadCheckout", 
            checkout: checkout.checkout
          })
        //}
      }
    
      const fetchCheckout = async (id) => {
        const rawCart = await API.graphql(graphqlOperation(queries.getCheckoutCarts, {id:id}))
        const jsonCart =  await JSON.parse(rawCart.data.getCheckoutCarts.cart)
        const cartToLoad = 
          { "checkout": 
            { 
              "cart": jsonCart.cart || {},
              "totalLineItems": rawCart.data.getCheckoutCarts.totalLineItems|| 0, 
              "cartSubTotal" : rawCart.data.getCheckoutCarts.cartSubTotal || 0, 
              "id" : id 
            }
          }
        setCheckoutInState(cartToLoad)
      }

      if (existingCheckoutID) {
        const fetched = await fetchCheckout(existingCheckoutID)
                  
          //try {
          // Make sure this cart hasn’t already been purchased.
          // if (!checkout.completedAt) {
          //   setCheckoutInState(checkout)
          //   return
          // }
        //
      } else {
        const newCheckout = await createNewCheckout()
        setCheckoutInState(newCheckout)
      }

    }

    initializeCheckout()
  }, [])
return (null)
}

export function createNewCheckout () {
  let checkoutId = createUUID()
  localStorage.setItem('cColectivo_checkout_id', checkoutId)
  saveCartToDB('create', checkoutId, {},0, 0)
  return (
      { 
        "checkout": 
        { 
          "cart": {},
          "totalLineItems": 0, 
          "cartSubTotal" : 0, 
          "id" : checkoutId 
        }
      }
    )
  }