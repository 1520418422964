import React, {useState} from 'react'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import IconButton from '@material-ui/core/IconButton';
import { useStateValue } from "../../state";
import AlertDialogSlide from "../alertDialogSlide"

export default function AddCartButton(props){
  const [trueFalse, setTrueFalse] = useState(false);
  const [, dispatch] = useStateValue();
  const handleAddToCart = function(e) {
    dispatch({
      type: "addVariantToCart",
      variantId: props.id,
      name: props.name,
      image: props.image,
      salePrice: props.salePrice,
      regularPrice: props.regularPrice,
      storeId: props.storeId,
      storeName: props.storeName,
      storeEmail: props.storeEmail,
      quantity: 1
    });
    toggleFunction()
  }
  const toggleFunction = function (){
    setTrueFalse(!trueFalse)
  }
return(
  <React.Fragment>
    <IconButton color="primary" aria-label="add to shopping cart" onClick={handleAddToCart}>
        <AddShoppingCartIcon />
    </IconButton>
    <AlertDialogSlide 
      toggleFunction={toggleFunction} 
      displayModal={trueFalse}
      productName={props.name}
      image={props.image}
      salePrice={props.salePrice}
      regularPrice={props.regular}/>
  </React.Fragment>
  )
}

