/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ab63f8ac-6720-4bdb-987f-c76853379137",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_zv2NCu1W9",
    "aws_user_pools_web_client_id": "6jo8e2t8gogee08vl8ccjgvpkr",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://atjhk2c6wjci7jsk2gaazhpbpe.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://2x6xg8uc81.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
