import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useStateValue } from "../../state";
import {useParams} from 'react-router-dom'
import {loadOrder} from '../../services'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: 'inherit',
    margin: '10px'
  },
  cardContent: {
    flexGrow: 1,
  }
}));
const formatter = new Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0
})


export default function Order() {
  const classes = useStyles()
  const [state, dispatch] = useStateValue()
  const {slug} = useParams();
  const [order, setOrder] = useState({id:''})
  const [name, setName] = useState('')
  const [orderProducts, setOrderProducts] = useState('')
  // const processOrderedProducts = async (productString)=>{
  //   const jsonProds = await JSON.parse(productString)
  //   setOrderProducts(jsonProds)
  // }
 
  useEffect(() => {
    loadOrder(slug).then((res)=>{
      setOrder(res.data.getOrders)
      setName(res.data.getOrders.user.items[0].user.firstName + ' ' + res.data.getOrders.user.items[0].user.lastName)
      setOrderProducts(JSON.parse(res.data.getOrders.products))
    });
  }, []);

  return (
<React.Fragment>
  <Container className={classes.cardGrid} maxWidth="md">
    <Grid container spacing={4} >
      <Grid item xs={12}><Typography align="center" variant="h5">Resumen de Pedido</Typography></Grid>
      <Grid item xs={12} md={6}>
        Gracias, su orden de compra ha sido enviada al proveedor. 
      </Grid>
      <Grid item xs={6} md={6}>
        
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography>Numero de Orden: {order.id} </Typography> 
        <Typography>Nombre: {name} </Typography>
        <Typography>Telefono: {(order.user == null)?'':order.user.items[0].user.phone}</Typography>
        
        <Grid container spacing={2} alignItems="center">
          {Object.keys(orderProducts).map((store)=>(

            <Card className={classes.card} variant="outlined">
            
              <CardContent className={classes.cardContent}>
                <Typography>Tienda: {orderProducts[store].name}</Typography>
                  <Grid container alignItems="center">
                  {Object.keys(orderProducts[store].products).map((product)=>
                    (
                      <React.Fragment>
                        <Grid item xs={4}>
                          <List className={classes.root}>
                            <ListItem alignItems="flex-start">
                              <ListItemText
                                primary={orderProducts[store].products[product].name}
                                secondary={formatter.format(orderProducts[store].products[product].salePrice)}
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid item xs={4}>
                          <Box display="flex" justifyContent="center" alignItems="center">
                            <Typography className={classes.quantity}>x {orderProducts[store].products[product].quantity}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box display="flex" justifyContent="center" alignItems="center">
                            <Typography>{formatter.format(orderProducts[store].storeSubTotal)}</Typography>
                          </Box>
                        </Grid>
                      
                    </React.Fragment>
                    ))}
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}><Box display="flex" justifyContent="center" alignItems="center"><Typography>SubTotal: </Typography></Box></Grid>  
                  <Grid item xs={4}><Box display="flex" justifyContent="center" alignItems="center"><Typography>{formatter.format(orderProducts[store].storeSubTotal)}</Typography></Box></Grid>    
                </Grid>
              </CardContent>
            </Card>
            
        
                                    
    
          ))}
        </Grid>
      </Grid>
    </Grid>
  </Container>
</React.Fragment>
  );
}
