import React, { useState, useEffect } from "react";
import {useParams, Redirect} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import ProductCard from "../productCard";
import { API, graphqlOperation } from "aws-amplify";
import Skeleton from '@material-ui/lab/Skeleton';

const listProducts = `query GetStoreByAppLink($url:String) {
  getStoreByAppLink(appLink: $url)
  {
    items{
      name
      id
      profileImages
      description
      appLink
      city
      state
      country
      email
      products{
        items{
          product{
            name
            id
            description
            images
            regularPrice
            salePrice
          }
        }
      }
    }
  }
}`;


export default function ProductGrid(props) {
  const classes = useStyles();
  let {url} = useParams();
  const [storeInfo, setStoreInfo] = useState({
        name:"", 
        id: "",
        description:"", 
        profileImages:[""], 
        city:"", 
        state:"", 
        country:"", 
        email:"", 
        appLink:"",
        products: {items:[{product:{name:"", id:"", salePrice:0, regularPrice:0, images:['']}}]}
      });

  const productList = storeInfo.products.items.map((card) => (
    <Grid item key={'grid'+card.product.id} xs={4} sm={4} md={3}>
      <ProductCard 
        key= {'card'+card.product.id} 
        name={card.product.name} 
        id={card.product.id} 
        storeId = {storeInfo.id}
        storeName = {storeInfo.name}
        storeEmail = {storeInfo.email.trim()}
        description={card.product.description}
        images={(card.product.images[0]!== "" && card.product.images!= null)?(card.product.images[0]).replace("image/upload/", "image/upload/c_thumb,w_300/"):"https://res.cloudinary.com/ccolectivo/image/upload/c_thumb,w_300,g_face/v1589652793/stores/placeholder_es45gj.jpg"}
        salePrice={card.product.salePrice}
        regularPrice={card.product.regularPrice}/>
    </Grid>
  ))

const skeletonLoader =     
  <React.Fragment>
    <Grid key={'skeleton1'} item  xs={4} sm={4} md={3}>
      <Skeleton variant="rect" width={210} height={118} />
      <Skeleton variant="text" />
    </Grid>
    <Grid key={'skeleton2'} item  xs={4} sm={4} md={3}>
      <Skeleton variant="rect" width={210} height={118} />
      <Skeleton variant="text" />
    </Grid>
    <Grid key={'skeleton3'} item  xs={4} sm={4} md={3}>
      <Skeleton variant="rect" width={210} height={118} />
      <Skeleton variant="text" />
    </Grid>
    <Grid key={'skeleton4'} item  xs={4} sm={4} md={3}>
      <Skeleton variant="rect" width={210} height={118} />
      <Skeleton variant="text" />
    </Grid>
  </React.Fragment>
  
  let listQuery = async () => {
    let queryResults = await API.graphql(graphqlOperation(listProducts, {url: url.toLowerCase()}));
    return queryResults;
    };

  useEffect(() => {
    listQuery().then((queryResults)=>{
      if(queryResults.data.getStoreByAppLink.items.length){
        setStoreInfo(queryResults.data.getStoreByAppLink.items[0])
      } else {
        setStoreInfo({name:"404"})
        
      }
    });
  }, []);
if(storeInfo.name === "404"){
  return <Redirect to="/home"/>
}
  return (
    <React.Fragment>
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                    {(storeInfo.profileImages[0] === "")?<Skeleton variant="rect" width={210} height={118} />:<img className={classes.image} alt={storeInfo.name} src={(storeInfo.profileImages[0]).replace("image/upload/", "image/upload/c_thumb,w_300/")}/>}
                </Grid>
                <Grid item xs={9} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography gutterBottom variant="subtitle1">
                        {storeInfo.name}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {storeInfo.description}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {storeInfo.city}, {storeInfo.state}  {storeInfo.country}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {storeInfo.email}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* <Grid item>
                    <Typography variant="subtitle1">{storeInfo.appLink}</Typography>
                  </Grid> */}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        {(storeInfo.id === "")?skeletonLoader:productList}
        </Grid>
      </Container>
    </React.Fragment>
  );
  }


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto'
  },
  image: {
    width: '100%'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  }
}));