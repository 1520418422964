import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Link as RouterLink} from 'react-router-dom'
import Link from '@material-ui/core/Link';
import AddCartButton from '../addCartButton'
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  }, 
  addCartBox: {
    textAlign: 'center'
  }

}));
const formatter = new Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0
})

function ProductCard(props){
  const classes = useStyles();
  return(
    // <Link to= {"/" + props.cardTitle} underline="none" component={RouterLink}>
    <Card className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image={props.images}
        title="Image title"
      />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="subtitle1" component="h5">
          {props.name}
        </Typography>
        <Typography>
          {formatter.format(props.salePrice)}
        </Typography>
        <Box className={classes.addCartBox}>
          <AddCartButton 
            id={props.id} 
            name={props.name}
            storeId={props.storeId}
            storeName={props.storeName}
            storeEmail={props.storeEmail}
            image={props.images}
            salePrice={props.salePrice}
            regularPrice={props.regularPrice}
            />
        </Box>
      </CardContent>
    </Card>
    // </Link>
  )
}
export default ProductCard
