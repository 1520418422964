/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStore = /* GraphQL */ `
  mutation CreateStore(
    $input: CreateStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    createStore(input: $input, condition: $condition) {
      id
      name
      profileImages
      keywords
      description
      wwwURL
      appLink
      languages
      address
      address2
      address3
      city
      state
      zip
      country
      phone
      email
      paymentMethod
      products {
        items {
          id
          storeID
          productID
        }
        nextToken
      }
    }
  }
`;
export const updateStore = /* GraphQL */ `
  mutation UpdateStore(
    $input: UpdateStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    updateStore(input: $input, condition: $condition) {
      id
      name
      profileImages
      keywords
      description
      wwwURL
      appLink
      languages
      address
      address2
      address3
      city
      state
      zip
      country
      phone
      email
      paymentMethod
      products {
        items {
          id
          storeID
          productID
        }
        nextToken
      }
    }
  }
`;
export const deleteStore = /* GraphQL */ `
  mutation DeleteStore(
    $input: DeleteStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    deleteStore(input: $input, condition: $condition) {
      id
      name
      profileImages
      keywords
      description
      wwwURL
      appLink
      languages
      address
      address2
      address3
      city
      state
      zip
      country
      phone
      email
      paymentMethod
      products {
        items {
          id
          storeID
          productID
        }
        nextToken
      }
    }
  }
`;
export const createStoreProduct = /* GraphQL */ `
  mutation CreateStoreProduct(
    $input: CreateStoreProductInput!
    $condition: ModelStoreProductConditionInput
  ) {
    createStoreProduct(input: $input, condition: $condition) {
      id
      storeID
      productID
      store {
        id
        name
        profileImages
        keywords
        description
        wwwURL
        appLink
        languages
        address
        address2
        address3
        city
        state
        zip
        country
        phone
        email
        paymentMethod
        products {
          nextToken
        }
      }
      product {
        id
        name
        upc
        originCountry
        manufacturerID
        images
        video
        language
        description
        dimensions
        dimensionsUnit
        weight
        weightUnit
        regularPrice
        salePrice
        options {
          name
          description
          extension
          regularPrice
          salePrice
        }
        averageReview
        reviews {
          id
          caption
          rating
          review
          reviewDate
        }
        stores {
          nextToken
        }
      }
    }
  }
`;
export const updateStoreProduct = /* GraphQL */ `
  mutation UpdateStoreProduct(
    $input: UpdateStoreProductInput!
    $condition: ModelStoreProductConditionInput
  ) {
    updateStoreProduct(input: $input, condition: $condition) {
      id
      storeID
      productID
      store {
        id
        name
        profileImages
        keywords
        description
        wwwURL
        appLink
        languages
        address
        address2
        address3
        city
        state
        zip
        country
        phone
        email
        paymentMethod
        products {
          nextToken
        }
      }
      product {
        id
        name
        upc
        originCountry
        manufacturerID
        images
        video
        language
        description
        dimensions
        dimensionsUnit
        weight
        weightUnit
        regularPrice
        salePrice
        options {
          name
          description
          extension
          regularPrice
          salePrice
        }
        averageReview
        reviews {
          id
          caption
          rating
          review
          reviewDate
        }
        stores {
          nextToken
        }
      }
    }
  }
`;
export const deleteStoreProduct = /* GraphQL */ `
  mutation DeleteStoreProduct(
    $input: DeleteStoreProductInput!
    $condition: ModelStoreProductConditionInput
  ) {
    deleteStoreProduct(input: $input, condition: $condition) {
      id
      storeID
      productID
      store {
        id
        name
        profileImages
        keywords
        description
        wwwURL
        appLink
        languages
        address
        address2
        address3
        city
        state
        zip
        country
        phone
        email
        paymentMethod
        products {
          nextToken
        }
      }
      product {
        id
        name
        upc
        originCountry
        manufacturerID
        images
        video
        language
        description
        dimensions
        dimensionsUnit
        weight
        weightUnit
        regularPrice
        salePrice
        options {
          name
          description
          extension
          regularPrice
          salePrice
        }
        averageReview
        reviews {
          id
          caption
          rating
          review
          reviewDate
        }
        stores {
          nextToken
        }
      }
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      upc
      originCountry
      manufacturerID
      images
      video
      language
      description
      dimensions
      dimensionsUnit
      weight
      weightUnit
      regularPrice
      salePrice
      options {
        name
        description
        extension
        regularPrice
        salePrice
      }
      averageReview
      reviews {
        id
        caption
        rating
        review
        reviewDate
      }
      stores {
        items {
          id
          storeID
          productID
        }
        nextToken
      }
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      upc
      originCountry
      manufacturerID
      images
      video
      language
      description
      dimensions
      dimensionsUnit
      weight
      weightUnit
      regularPrice
      salePrice
      options {
        name
        description
        extension
        regularPrice
        salePrice
      }
      averageReview
      reviews {
        id
        caption
        rating
        review
        reviewDate
      }
      stores {
        items {
          id
          storeID
          productID
        }
        nextToken
      }
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      upc
      originCountry
      manufacturerID
      images
      video
      language
      description
      dimensions
      dimensionsUnit
      weight
      weightUnit
      regularPrice
      salePrice
      options {
        name
        description
        extension
        regularPrice
        salePrice
      }
      averageReview
      reviews {
        id
        caption
        rating
        review
        reviewDate
      }
      stores {
        items {
          id
          storeID
          productID
        }
        nextToken
      }
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      firstName
      lastName
      phone
      email
      roles
      favoriteStores
      paymentMethod
      address {
        id
        address
        address2
        address3
        city
        state
        zip
        country
        type
      }
      orders {
        items {
          id
          userId
          orderId
        }
        nextToken
      }
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      firstName
      lastName
      phone
      email
      roles
      favoriteStores
      paymentMethod
      address {
        id
        address
        address2
        address3
        city
        state
        zip
        country
        type
      }
      orders {
        items {
          id
          userId
          orderId
        }
        nextToken
      }
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      firstName
      lastName
      phone
      email
      roles
      favoriteStores
      paymentMethod
      address {
        id
        address
        address2
        address3
        city
        state
        zip
        country
        type
      }
      orders {
        items {
          id
          userId
          orderId
        }
        nextToken
      }
    }
  }
`;
export const createOrders = /* GraphQL */ `
  mutation CreateOrders(
    $input: CreateOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    createOrders(input: $input, condition: $condition) {
      id
      cartId
      products
      userId
      customer
      vendor
      orderDate
      paymentMethod
      notes
      user {
        items {
          id
          userId
          orderId
        }
        nextToken
      }
    }
  }
`;
export const updateOrders = /* GraphQL */ `
  mutation UpdateOrders(
    $input: UpdateOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    updateOrders(input: $input, condition: $condition) {
      id
      cartId
      products
      userId
      customer
      vendor
      orderDate
      paymentMethod
      notes
      user {
        items {
          id
          userId
          orderId
        }
        nextToken
      }
    }
  }
`;
export const deleteOrders = /* GraphQL */ `
  mutation DeleteOrders(
    $input: DeleteOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    deleteOrders(input: $input, condition: $condition) {
      id
      cartId
      products
      userId
      customer
      vendor
      orderDate
      paymentMethod
      notes
      user {
        items {
          id
          userId
          orderId
        }
        nextToken
      }
    }
  }
`;
export const createUserOrders = /* GraphQL */ `
  mutation CreateUserOrders(
    $input: CreateUserOrdersInput!
    $condition: ModelUserOrdersConditionInput
  ) {
    createUserOrders(input: $input, condition: $condition) {
      id
      userId
      orderId
      user {
        id
        firstName
        lastName
        phone
        email
        roles
        favoriteStores
        paymentMethod
        address {
          id
          address
          address2
          address3
          city
          state
          zip
          country
          type
        }
        orders {
          nextToken
        }
      }
      order {
        id
        cartId
        products
        userId
        customer
        vendor
        orderDate
        paymentMethod
        notes
        user {
          nextToken
        }
      }
    }
  }
`;
export const updateUserOrders = /* GraphQL */ `
  mutation UpdateUserOrders(
    $input: UpdateUserOrdersInput!
    $condition: ModelUserOrdersConditionInput
  ) {
    updateUserOrders(input: $input, condition: $condition) {
      id
      userId
      orderId
      user {
        id
        firstName
        lastName
        phone
        email
        roles
        favoriteStores
        paymentMethod
        address {
          id
          address
          address2
          address3
          city
          state
          zip
          country
          type
        }
        orders {
          nextToken
        }
      }
      order {
        id
        cartId
        products
        userId
        customer
        vendor
        orderDate
        paymentMethod
        notes
        user {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserOrders = /* GraphQL */ `
  mutation DeleteUserOrders(
    $input: DeleteUserOrdersInput!
    $condition: ModelUserOrdersConditionInput
  ) {
    deleteUserOrders(input: $input, condition: $condition) {
      id
      userId
      orderId
      user {
        id
        firstName
        lastName
        phone
        email
        roles
        favoriteStores
        paymentMethod
        address {
          id
          address
          address2
          address3
          city
          state
          zip
          country
          type
        }
        orders {
          nextToken
        }
      }
      order {
        id
        cartId
        products
        userId
        customer
        vendor
        orderDate
        paymentMethod
        notes
        user {
          nextToken
        }
      }
    }
  }
`;
export const createCheckoutCarts = /* GraphQL */ `
  mutation CreateCheckoutCarts(
    $input: CreateCheckoutCartsInput!
    $condition: ModelcheckoutCartsConditionInput
  ) {
    createCheckoutCarts(input: $input, condition: $condition) {
      id
      cart
      totalLineItems
      cartSubTotal
      createdOn
      completedOn
    }
  }
`;
export const updateCheckoutCarts = /* GraphQL */ `
  mutation UpdateCheckoutCarts(
    $input: UpdateCheckoutCartsInput!
    $condition: ModelcheckoutCartsConditionInput
  ) {
    updateCheckoutCarts(input: $input, condition: $condition) {
      id
      cart
      totalLineItems
      cartSubTotal
      createdOn
      completedOn
    }
  }
`;
export const deleteCheckoutCarts = /* GraphQL */ `
  mutation DeleteCheckoutCarts(
    $input: DeleteCheckoutCartsInput!
    $condition: ModelcheckoutCartsConditionInput
  ) {
    deleteCheckoutCarts(input: $input, condition: $condition) {
      id
      cart
      totalLineItems
      cartSubTotal
      createdOn
      completedOn
    }
  }
`;
