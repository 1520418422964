/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getStore = /* GraphQL */ `
  query GetStore($id: ID!) {
    getStore(id: $id) {
      id
      name
      profileImages
      keywords
      description
      wwwURL
      appLink
      languages
      address
      address2
      address3
      city
      state
      zip
      country
      phone
      email
      paymentMethod
      products {
        items {
          id
          storeID
          productID
        }
        nextToken
      }
    }
  }
`;
export const listStores = /* GraphQL */ `
  query ListStores(
    $filter: ModelStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        profileImages
        keywords
        description
        wwwURL
        appLink
        languages
        address
        address2
        address3
        city
        state
        zip
        country
        phone
        email
        paymentMethod
        products {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      upc
      originCountry
      manufacturerID
      images
      video
      language
      description
      dimensions
      dimensionsUnit
      weight
      weightUnit
      regularPrice
      salePrice
      options {
        name
        description
        extension
        regularPrice
        salePrice
      }
      averageReview
      reviews {
        id
        caption
        rating
        review
        reviewDate
      }
      stores {
        items {
          id
          storeID
          productID
        }
        nextToken
      }
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        upc
        originCountry
        manufacturerID
        images
        video
        language
        description
        dimensions
        dimensionsUnit
        weight
        weightUnit
        regularPrice
        salePrice
        options {
          name
          description
          extension
          regularPrice
          salePrice
        }
        averageReview
        reviews {
          id
          caption
          rating
          review
          reviewDate
        }
        stores {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      firstName
      lastName
      phone
      email
      roles
      favoriteStores
      paymentMethod
      address {
        id
        address
        address2
        address3
        city
        state
        zip
        country
        type
      }
      orders {
        items {
          id
          userId
          orderId
        }
        nextToken
      }
    }
  }
`;
export const listUserss = /* GraphQL */ `
  query ListUserss(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        phone
        email
        roles
        favoriteStores
        paymentMethod
        address {
          id
          address
          address2
          address3
          city
          state
          zip
          country
          type
        }
        orders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOrders = /* GraphQL */ `
  query GetOrders($id: ID!) {
    getOrders(id: $id) {
      id
      cartId
      products
      userId
      customer
      vendor
      orderDate
      paymentMethod
      notes
      user {
        items {
          id
          userId
          orderId
        }
        nextToken
      }
    }
  }
`;
export const listOrderss = /* GraphQL */ `
  query ListOrderss(
    $filter: ModelOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cartId
        products
        userId
        customer
        vendor
        orderDate
        paymentMethod
        notes
        user {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCheckoutCarts = /* GraphQL */ `
  query GetCheckoutCarts($id: ID!) {
    getCheckoutCarts(id: $id) {
      id
      cart
      totalLineItems
      cartSubTotal
      createdOn
      completedOn
    }
  }
`;
export const listCheckoutCartss = /* GraphQL */ `
  query ListCheckoutCartss(
    $filter: ModelcheckoutCartsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckoutCartss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cart
        totalLineItems
        cartSubTotal
        createdOn
        completedOn
      }
      nextToken
    }
  }
`;
export const getStoreByAppLink = /* GraphQL */ `
  query GetStoreByAppLink(
    $appLink: String
    $sortDirection: ModelSortDirection
    $filter: ModelStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStoreByAppLink(
      appLink: $appLink
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        profileImages
        keywords
        description
        wwwURL
        appLink
        languages
        address
        address2
        address3
        city
        state
        zip
        country
        phone
        email
        paymentMethod
        products {
          nextToken
        }
      }
      nextToken
    }
  }
`;
