import React, {useEffect} from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import Store from "./components/routes/store";
import Home from "./components/routes/home";
import Cart from "./components/routes/cart"
import Checkout from "./components/routes/checkout"
import Order from "./components/routes/order"
import Search from "./components/routes/search"
import CssBaseline from "@material-ui/core/CssBaseline";
import CustomFooter from "./components/view/customFooter";
import CustomHeader from "./components/view/cutomHeader";
import { StateProvider } from "./components/state";
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import * as uuid from "uuid";
import {saveCartToDB, LoadCart} from "./components/services"


const createUUID = ()=>uuid.v4()

const theme = createMuiTheme({
  palette: {
    primary: teal,
  },
});

function App() {
  let initialStoreState = {
    "storeList": [{ 
      "id": "", 
      "name": "Loading...",
      "appLink": "", 
      "profileImages": ["https://res.cloudinary.com/ccolectivo/image/upload/c_thumb,w_300,g_face/v1589652793/stores/placeholder_es45gj.jpg"],
      "description": "" }],
    "adding": false,
    "checkout": { 
      "cart": {},
      "totalLineItems": 0, 
      "cartSubTotal" : 0, 
      "id" : ""
  }, 
    "products": [],
    "shop": {},
    "currentStore": "",
    "searchResults": ["''"]
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "updateStoreList":
        return {
          ...state,
          storeList: action.storeList,
        };
      case "updateSearchResults":
        return{
          ...state,
          searchResults: action.searchResults
        };
      case "loadCheckout":
        return {
          ...state, 
          checkout: action.checkout
          
        };
      case "addVariantToCart":
        let variantId = action.variantId
        let storeId = action.storeId
        let productName = action.name
        let storeName = action.storeName
        let storeEmail = action.storeEmail
        let itemToAddtoCart = state.checkout.cart
        if (variantId === '' || !action.quantity) {
          console.error('Both a size and quantity are required.')
          //return 
        }
        itemToAddtoCart[storeId] = itemToAddtoCart[storeId] || {}
        itemToAddtoCart[storeId].name = (storeName != null)?storeName:itemToAddtoCart[storeId].name
        itemToAddtoCart[storeId].email = (storeEmail != null)?storeEmail:itemToAddtoCart[storeId].email
        itemToAddtoCart[storeId].products = (itemToAddtoCart[storeId].products)?itemToAddtoCart[storeId].products:{}
        itemToAddtoCart[storeId].products[variantId] = (itemToAddtoCart[storeId].products[variantId]) || {}
        itemToAddtoCart[storeId].products[variantId].quantity = (itemToAddtoCart[storeId].products[variantId].quantity != null)? (itemToAddtoCart[storeId].products[variantId].quantity + action.quantity):action.quantity
        itemToAddtoCart[storeId].products[variantId].name = (productName != null)?productName:itemToAddtoCart[storeId].products[variantId].name
        itemToAddtoCart[storeId].products[variantId].image = (action.image != null)?action.image:itemToAddtoCart[storeId].products[variantId].image
        itemToAddtoCart[storeId].products[variantId].salePrice = (action.salePrice != null)?action.salePrice:itemToAddtoCart[storeId].products[variantId].salePrice
        itemToAddtoCart[storeId].products[variantId].productSubTotal = itemToAddtoCart[storeId].products[variantId].quantity * itemToAddtoCart[storeId].products[variantId].salePrice
        itemToAddtoCart[storeId].storeSubTotal = (itemToAddtoCart[storeId].storeSubTotal != null)?(itemToAddtoCart[storeId].storeSubTotal + (action.quantity * itemToAddtoCart[storeId].products[variantId].salePrice)):itemToAddtoCart[storeId].products[variantId].productSubTotal

        if(itemToAddtoCart[storeId].products[variantId].quantity < 1){ 
        //removes the item from the cart if the user updates the quantity on the cart page to 0
          delete itemToAddtoCart[storeId].products[variantId]
          if(Object.keys(itemToAddtoCart[storeId].products).length === 0){
            delete itemToAddtoCart[storeId]
          }
        }

        let totalLineItems = parseInt(state.checkout.totalLineItems, 10) + parseInt(action.quantity, 10)
        saveCartToDB('update', localStorage.getItem('cColectivo_checkout_id'), {cart: itemToAddtoCart}, totalLineItems)
        return {
          ...state,
          checkout: {
              cart: itemToAddtoCart,
              totalLineItems: totalLineItems,
              id: localStorage.getItem('cColectivo_checkout_id')
          }
        };
      default:
        return state
    }
  };

  return (
      <StateProvider initialState={initialStoreState} reducer={reducer}>
        <LoadCart></LoadCart>
        <ThemeProvider theme={theme}>
        <CssBaseline />
        <main>
          <CustomHeader />
          <div>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/home">
                <Home />
              </Route>
              <Route path="/cart">
                <Cart/>
              </Route>
              <Route path="/checkout">
                <Checkout/>
              </Route>
              <Route path="/order/:slug">
                <Order/>
              </Route>
              <Route path="/search">
                <Search/>
              </Route>
              <Route path="/:url">
                <Store />
              </Route>
            </Switch>
          </div>
          <CustomFooter />
        </main>
        </ThemeProvider>
      </StateProvider>
  );
}
export default App;