import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HomeIcon from '@material-ui/icons/Home';
import {Link as RouterLink} from 'react-router-dom'
import { useStateValue } from "../../state";
import SearchBar from './searchBar'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    textDecoration: 'none',
    color: 'white'
  },
  homeIcon:{
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  sectionDesktop: {
    display: 'flex'
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function CustomHeader() {
  const [state, dispatch] = useStateValue();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} 
            variant="h6" 
            noWrap 
            to="/" 
            component={RouterLink} 
            underline="none"
            onClick={()=>dispatch({
                type: "updateSearchResults",
                searchResults: '',
              })}>
            Comercio Colectivo
          </Typography>
          <IconButton 
            to="/" 
            component={RouterLink} 
            underline="none"
            onClick={()=>dispatch({
                type: "updateSearchResults",
                searchResults: '',
              })}>
            <HomeIcon className={classes.homeIcon} color="action"/>
          </IconButton>
          <SearchBar/>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton aria-label="shopping cart icon" color="inherit" to="cart" component={RouterLink}>
              <Badge badgeContent={state.checkout.totalLineItems || 0} color="secondary">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
            {/* <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton> */}
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
}
